import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/default-layout.js";
import { Link } from 'gatsby';
import { Box, Stack, Badge, PseudoBox } from "@chakra-ui/core";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Publications`}</h1>
    <hr></hr>
    <Link to='/publications/PERC2020' mdxType="Link">
    <Stack as='article' bg='teal.50' p='6' border='1px' borderLeft='8px solid' borderColor='teal.300' borderRadius='md' mt='6' mdxType="Stack">
        <Box fontWeight='bold' fontSize='lg' mdxType="Box">Examining consistency of student errors in vector operations using module analysis</Box>
        <Stack isInline alignItems='baseline' mdxType="Stack">
            <Box mdxType="Box">Nekeisha&nbsp;Johnson, John&nbsp;B.&nbsp;Buncher</Box>
            <Badge variant='solid' variantColor='teal' mdxType="Badge">PERC 2020</Badge>
        </Stack>
    </Stack>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      